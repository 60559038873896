import React from "react";
import { Button } from "../Button/Button";
import Accordion from "./Accordion";
const styles = require("./HomeAccordion.module.scss");

const HomeAccordion = (props) => {
  const { panelData } = props;
  return (
    <div className={styles.accordionWrapper}>
      <Accordion
        transitionTime={300}
        triggerClassName={styles.headerClosed}
        triggerOpenedClassName={styles.headerOpen}
        classParentString={styles.accordionSection}
        triggerOpenedClassName={styles.headerOpen}
        openedClassName={styles.accordionOpen}
        closeable={true}
      >
        {panelData.map((panel) => (
          <div data-trigger={panel.title}>
            <div className={styles.copy}>
              <h3 className={styles.heading}>{panel.heading}</h3>
              <p>{panel.copy}</p>
              {panel.cta
                ? panel.cta.map((cta) => (
                    <Button
                      label={cta.text}
                      type="internalLink"
                      toPage={cta.link}
                    />
                  ))
                : null}
            </div>
            <div className={styles.shapes}>
              <div className={styles.shapesLeft}>
                <div className={`${styles.shape1}  ${styles.shape}`}></div>
                <div className={`${styles.shape2}  ${styles.shape}`}></div>
                <div className={`${styles.shape3}  ${styles.shape}`}></div>
              </div>
              <div className={styles.shapesRight}>
                <div className={`${styles.shape1}  ${styles.shape}`}></div>
                <div className={`${styles.shape2}  ${styles.shape}`}></div>
                <div className={`${styles.shape3}  ${styles.shape}`}></div>
              </div>
            </div>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default HomeAccordion;
