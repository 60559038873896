import React, { useState, useEffect } from "react";
import Collapsible from "react-collapsible";

const Accordion = (props) => {
  const {
    transitionTime,
    transitionCloseTime,
    triggerClassName = "",
    triggerTagName,
    easing,
    startPosition = 0,
    classParentString = "",
    onTriggerClick = () => void 0,
    closeable = false,
    children,
    triggerOpenedClassName = "",
    openedClassName,
    careersPage = false,
  } = props;

  const [openPosition, setOpenPosition] = useState(startPosition | 0);
  const [closeAll, setCloseAll] = useState(false);

  const handleTriggerClick = (position) => {
    let closeAllHere = false;

    if (closeable) {
      closeAllHere = !closeAll && position === openPosition;
    }

    setOpenPosition(position);
    setCloseAll(closeAllHere);

    onTriggerClick(position);
  };

  useEffect(() => {
    if (careersPage && closeAll) {
      var noHashURL = window.location.href.replace(/#.*$/, "");
      window.history.replaceState("", document.title, noHashURL);
    }
  }, [closeAll]);

  const nodes = children.map((node, index) => {
    var triggerWhenOpen = node.props["data-trigger-when-open"]
      ? node.props["data-trigger-when-open"]
      : node.props["data-trigger"];
    var triggerDisabled = node.props["data-trigger-disabled"] || false;

    return (
      <Collapsible
        key={"Collapsible" + index}
        handleTriggerClick={handleTriggerClick}
        open={!closeAll && openPosition === index}
        trigger={node.props["data-trigger"]}
        triggerWhenOpen={triggerWhenOpen}
        triggerTagName={triggerTagName}
        triggerDisabled={triggerDisabled}
        triggerOpenedClassName={triggerOpenedClassName}
        transitionTime={transitionTime}
        transitionCloseTime={transitionCloseTime}
        triggerClassName={triggerClassName}
        easing={easing}
        classParentString={classParentString}
        accordionPosition={index}
        openedClassName={openedClassName}
      >
        {node}
      </Collapsible>
    );
  });

  return <div>{nodes}</div>;
};

export default Accordion;
