// extracted by mini-css-extract-plugin
export var accordion = "HomeAccordion-module--accordion--VWfEU";
export var accordionOpen = "HomeAccordion-module--accordionOpen--KQc4a";
export var accordionSection = "HomeAccordion-module--accordionSection--xePDB";
export var copy = "HomeAccordion-module--copy--i9OZC";
export var first = "HomeAccordion-module--first--5dbQX";
export var headerClosed = "HomeAccordion-module--headerClosed--hLIvs";
export var headerOpen = "HomeAccordion-module--headerOpen--Ec39j";
export var heading = "HomeAccordion-module--heading--F+JJT";
export var page404Title = "HomeAccordion-module--page404-title--ffMVm";
export var second = "HomeAccordion-module--second--JQUDY";
export var shape = "HomeAccordion-module--shape--JRO9K";
export var shape1 = "HomeAccordion-module--shape1--Im2Jy";
export var shape2 = "HomeAccordion-module--shape2--ujRtq";
export var shape3 = "HomeAccordion-module--shape3--PPTWM";
export var shapes = "HomeAccordion-module--shapes--w1MFV";
export var shapesLeft = "HomeAccordion-module--shapesLeft--8BERq";
export var shapesRight = "HomeAccordion-module--shapesRight--bxPGq";
export var third = "HomeAccordion-module--third--EgC2c";