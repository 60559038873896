// extracted by mini-css-extract-plugin
export var copy = "TabbedPanels-module--copy--YeScd";
export var first = "TabbedPanels-module--first--YibXw";
export var heading = "TabbedPanels-module--heading--P3lTg";
export var page404Title = "TabbedPanels-module--page404-title--FMtCw";
export var panel0 = "TabbedPanels-module--panel0---F2aA";
export var panel1 = "TabbedPanels-module--panel1--iTG0w";
export var panel2 = "TabbedPanels-module--panel2--cyiVl";
export var panel3 = "TabbedPanels-module--panel3--87bhf";
export var second = "TabbedPanels-module--second--V4gTR";
export var sectionHeading = "TabbedPanels-module--sectionHeading--03ttj";
export var shape = "TabbedPanels-module--shape--g6gLe";
export var shape1 = "TabbedPanels-module--shape1--Mra5b";
export var shape2 = "TabbedPanels-module--shape2--qN13o";
export var shape3 = "TabbedPanels-module--shape3--LWwkn";
export var shapes = "TabbedPanels-module--shapes--twdBJ";
export var shapesLeft = "TabbedPanels-module--shapesLeft--MWXkG";
export var shapesRight = "TabbedPanels-module--shapesRight--qbr3S";
export var tab = "TabbedPanels-module--tab--A2nyH";
export var tabPanel = "TabbedPanels-module--tabPanel--Q06Nv";
export var tabPanelSelected = "TabbedPanels-module--tabPanelSelected--5hrbw";
export var tabWrapper = "TabbedPanels-module--tabWrapper--ZTTPq";
export var tabs = "TabbedPanels-module--tabs--cvQQU";
export var third = "TabbedPanels-module--third--ePUSw";