import React, { useEffect } from "react";
import { Modal } from "react-responsive-modal";
import ReactPlayer from "react-player";
import { BgImage } from "gbimage-bridge";
import { Button } from "../Button/Button";
import playIcon from "../../images/icons/largePlayIcon.svg";
import pauseIcon from "../../images/icons/pauseIcon.svg";
import "react-responsive-modal/styles.css";
import { useKeenSlider } from "keen-slider/react";
import "../../styles/videoModal.css";
import { useInterval } from "../../hooks/useInterval";

const styles = require("./HomepageHero.module.scss");

const HomepageHero = ({ hero }) => {
  const [opacities, setOpacities] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [showPause, setShowPause] = React.useState(false);
  const [showPlay, setShowPlay] = React.useState(false);

  const myRef = React.useRef(null);
  const timer = React.useRef();

  const [sliderRef, slider] = useKeenSlider({
    slides: hero.headings.length,
    loop: true,
    duration: 1000,
    move(s) {
      const new_opacities = s.details().positions.map((slide) => slide.portion);
      setOpacities(new_opacities);
    },
  });

  // Created custom hook for using useInterval as per https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  useInterval(() => {
    if (slider) {
      slider.next();
    }
  }, 3000);

  const videoPause = () => {
    setShowPause(true);
    setShowPlay(false);
  };

  const videoPlay = () => {
    setShowPause(false);
    setShowPlay(true);
  };
  const bannerImageContent = (
    <>
      <video
        width="100%"
        height="100%"
        autoPlay="autoplay"
        loop
        muted
        playsInline
        className={styles.bannerVideo}
      >
        <source src={hero.bkgVideo} type="video/mp4" />
        <source src={hero.bkgVideo} type="video/webm" />
      </video>
      <div className={styles.bannerContent}>
        <div ref={sliderRef} className={styles.textSlider}>
          {sliderRef.current &&
            hero.headings.map((heading, index) => {
              return (
                <div
                  key={index}
                  className={styles.textSlide}
                  style={{ opacity: opacities[index] }}
                >
                  <h2 className={"h1"}>{heading.text}</h2>
                </div>
              );
            })}
        </div>
        <Button
          label={hero.ctaText}
          iconPosition="left"
          icon={require("../../images/icons/playIconDark.svg").default}
          bkgColor="white"
          handleOnClick={() => setOpen(true)}
        />
      </div>
    </>
  );

  return (
    <>
      {typeof hero.bannerImage === "string" ? (
        <section
          className={styles.banner}
          style={hero.bkgVideo ? { backgroundImage: hero.bannerImage } : ""}
        >
          <div className={styles.gradientCover}></div>
          {bannerImageContent}
        </section>
      ) : hero.bkgVideo ? (
        <section className={styles.banner}>
          <div className={styles.gradientCover}></div>
          {bannerImageContent}
        </section>
      ) : (
        <BgImage
          image={hero.bannerImage}
          Tag="section"
          className={styles.banner}
        >
          {bannerImageContent}
        </BgImage>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        container={myRef.current}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          closeIcon: "closeIcon",
          closeButton: "closeButton",
        }}
      >
        <div className={styles.playerWrapper}>
          <ReactPlayer
            className={styles.videoPlayer}
            url={hero.video}
            controls
            width="100%"
            height="calc(100vh - 2.4rem)"
            muted={false}
            onPause={videoPause}
            onPlay={videoPlay}
            playing
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
                tracks: [
                  {
                    kind: "subtitles",
                    src: hero.transcript,
                    srcLang: "en",
                    default: false,
                  },
                ],
              },
            }}
          />
          {showPause ? (
            <div className={styles.videoButton}>
              <img src={pauseIcon} alt="video paused" />
            </div>
          ) : null}
          {showPlay ? (
            <div className={styles.videoButton}>
              <img src={playIcon} alt="video playing" />
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default HomepageHero;
