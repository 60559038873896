import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useMedia } from "../../hooks/useMedia";
import useHasMounted from "../../hooks/useHasMounted";
import { Button } from "../Button/Button";
import HomeAccordion from "../Accordion/HomeAccordion";
import { navigate } from "gatsby";
const styles = require("./TabbedPanels.module.scss");

const TabbedPanels = (props) => {
  const { panelData, sectionHeader } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [activePanel, setActivePanel] = useState(styles["panel0"]);

  const hasMounted = useHasMounted();
  const { type } = useMedia(
    ["(min-width: 1440px)", "(min-width: 769px)", "(max-width: 768px)"],
    [
      {
        type: "desktop",
      },
      {
        type: "tablet",
      },

      { type: "mobile" },
    ],
    // Default object
    { type: "mobile" }
  );

  const changeTab = (index) => {
    const panel = "panel" + index;
    setActivePanel(styles[panel]);
    setTabIndex(index);
  };

  return (
    <>
      <h2 className={styles.sectionHeading}>{sectionHeader}</h2>
      {type === "mobile" && hasMounted ? (
        <HomeAccordion panelData={panelData} />
      ) : (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => changeTab(index)}
          className={styles.tabWrapper}
        >
          <TabList className={styles.tabs}>
            {panelData.map((panel) => (
              <Tab className={styles.tab}>{panel.title}</Tab>
            ))}
          </TabList>
          {panelData.map((panel, index) => {
            return (
              <TabPanel
                selectedClassName={styles.tabPanelSelected}
                className={`${styles.tabPanel} ${activePanel}`}
                key={index}
              >
                <div className={styles.copy}>
                  <h3 className={styles.heading}>{panel.heading}</h3>
                  <p>{panel.copy}</p>
                  {panel.cta
                    ? panel.cta.map((cta) => {
                        return (
                          <Button
                            label={cta.text}
                            type="internalLink"
                            toPage={cta.link}
                          />
                        );
                      })
                    : null}
                </div>
                <div className={styles.shapes}>
                  <div className={styles.shapesLeft}>
                    <div className={`${styles.shape1}  ${styles.shape}`}></div>
                    <div className={`${styles.shape2}  ${styles.shape}`}></div>
                    <div className={`${styles.shape3}  ${styles.shape}`}></div>
                  </div>
                  <div className={styles.shapesRight}>
                    <div className={`${styles.shape1}  ${styles.shape}`}></div>
                    <div className={`${styles.shape2}  ${styles.shape}`}></div>
                    <div className={`${styles.shape3}  ${styles.shape}`}></div>
                  </div>
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      )}
    </>
  );
};

export default TabbedPanels;
