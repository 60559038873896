import React from "react";
import { Button } from "../Button/Button";
import {
  title_container,
  content_container,
  cta_container,
  copy_container,
  title,
  content,
} from "./FeaturePanel.module.scss";
import parse from "html-react-parser";
import { navigate } from "gatsby";

const FeaturePanel = ({
  copy,
  cta,
  heading,
  headerImage,
  alignTitle = "center",
}) => {
  return (
    <div>
      <div className={title_container}>
        <img src={headerImage} alt="" />
        <div className={title}>
          <h2 style={{ align: alignTitle }}>{heading}</h2>
        </div>
      </div>
      {copy || cta?.length > 0 ? (
        <div className={content_container}>
          <div className={content}>
            {copy ? <div className={copy_container}>{parse(copy)}</div> : null}
            {cta?.length > 0 ? (
              <div className={cta_container}>
                {cta.map((button) => (
                  <Button
                    onClick={() => navigate(button.link)}
                    label={button.text}
                    bkgColor={button.text === "ffff" ? "black" : ""}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FeaturePanel;
