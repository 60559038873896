import * as React from "react";

import Layout from "../Layout";
import HomepageHero from "../HomepageHero/HomepageHero";
import TabbedPanels from "../TabbedPanels/TabbedPanels";
import WorkingWith from "../WorkingWith/WorkingWith";
import FeaturePanel from "../FeaturePanel/FeaturePanel";
import RelatedServices from "../RelatedServices/RelatedServices";

const Homepage = ({
  amxConnectHeading,
  featurePanelData,
  heroData,
  articlesData,
  panelData,
  workingWithData,
}) => {
  return (
    <Layout>
      <HomepageHero hero={heroData} />

      <TabbedPanels panelData={panelData} sectionHeader={amxConnectHeading} />

      <WorkingWith {...workingWithData} />

      <FeaturePanel {...featurePanelData} />

      <RelatedServices seeAll={true} articlesData={articlesData} />
    </Layout>
  );
};

export default Homepage;
